import "ol/ol.css";
import React, {Component} from "react";
import Map from "ol/Map";
import View from "ol/View";
import {Tile} from "ol/layer";
import * as control from "ol/control";
import BingMaps from "ol/source/BingMaps";

import "../../stylesheets/map.scss";

import {BING_API_KEY} from "../../utilities/resources";


export class BingMap extends Component {
	constructor(props) {
		super(props);

		this.map = null;
		this.rasters = [];

		this.props.imagerySets.forEach((set) => {
			this.rasters.push(
				new Tile({
					title: set.name,
					visible: set.visible,
					source: new BingMaps({
						key: BING_API_KEY,
						imagerySet: set.name,
					})
				})
			);
		});

		this.view = new View({
			center: this.props.center,
			maxZoom: this.props.maxZoom,
			zoom: this.props.zoom,
		});
	}

	componentDidMount() {
		this.map = new Map({
			layers: [...this.rasters, ...this.props.layers],
			target: this.props.id,
			view: this.view,
			controls: control.defaults({
				attributionOptions: {
					collapsible: true,
				},
			}),
		});

		if (this.props.onMapMount) this.props.onMapMount(this.map);

		this.map.on("singleclick", (event) => {
			if (this.props.onMapClick) this.props.onMapClick(event);
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.map.getView().setCenter(this.props.center);

		this.rasters.forEach((raster, index) => {
			raster.setVisible(index === this.props.activeImagery);
		});
	}

	render() {
		return (
			<div>
				<div id={this.props.id} className={this.props.className}/>
				{/*<Popup/>*/}
			</div>
		)
	}
}

BingMap.defaultProps = {
	id: "map",
	className: "map",
	maxZoom: 23,
	zoom: 17,
	imagerySets: [
		{name: "RoadOnDemand", visible: false},
		{name: "AerialWithLabelsOnDemand", visible: true},
	],
}
