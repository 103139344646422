import React, {memo} from "react";

import {
	Alert,
	Col,
	Container,
	Label,
	Row
} from "reactstrap";

import itree from "../../img/frontpagelogo.png";
import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";


export const PageLogo = memo(() => {

	return (
		<Row>
			<Col>
				<img
					className={"d-block mt-3 mx-auto"}
					src={itree}
					alt="i-Tree Logo"
				/>
			</Col>
		</Row>
	)
});


export const PageTitle = memo((props) => {
	let sizes = props.subtitle ? ["h5", "h6"] : ["h3", "h4"];

	return (
		<Row>
			<Col
				className={"text-center mt-1"}
				xs={{size: 12}}
			>
				<p className={`d-none d-sm-inline ${sizes[0]}`}>
					{props.text}
				</p>
				<p className={`d-inline d-sm-none ${sizes[1]}`}>
					{props.text}
				</p>
			</Col>
		</Row>
	)
});
PageTitle.defaultProps = {
	subtitle: false,
};


export const Footer = (props) => {

	return (
		<Container>
			<Row>
				{props["links"].map((l, i) => {
					return (
						<Col key={i} className={"text-center mt-3"}>
							<a href={l.href} target={l.target} rel={"noopener noreferrer"}>
								<img
									height={50}
									alt={l.alt}
									src={l.src}
								/>
							</a>
						</Col>
					)
				})}
			</Row>
		</Container>
	)
};


export const RequiredFields = memo(() => {

	return (
		<Row className={"mt-2"}>
			<Col
				xs={{size: 10}}
				sm={{offset: 1}}
				md={{size: 7, offset: 2}}
				lg={{size: 5, offset: 3}}
			>
				<Label className={"required"}></Label>
				<em>These fields are required.</em>
			</Col>
		</Row>
	)
});


export const AppVersion = (props) => {

	return (
		<sub className="ms-1 version">
			{props.version}
		</sub>
	)
};
AppVersion.defaultProps = {
	version: process.env.REACT_APP_VERSION
};


export const LocationWarning = () => {
	return (
		<React.Fragment>
			<small className={"text-center fst-italic"}>
				Some locations may fail to calculate if no
				matching location is found in our database
				&mdash; if your calculation fails please
				edit your location by spelling out any
				abbreviations or try using a nearby city.
			</small>
		</React.Fragment>
	)
};


export const CompanyInfo = () => {

	return (
		<Row>
			<Col>
				<p className={"text-center"}>
					For information and other tools,
					visit <a
					href={"https://www.itreetools.org/"}
					target={"_blank"}
					rel={"noopener noreferrer"}
				>
					www.itreetools.org
				</a>
				</p>
			</Col>
		</Row>
	)
}


export const DevelopmentWarning = () => {
	return (
		<Container>
			<Row>
				<Col className={"text-center"}>
					<Alert color={"danger"}>
						<FAI icon={["fas", "exclamation-triangle"]}/> WARNING:
						This is a development site. DO NOT USE. The public tool
						is <a href={"https://mytree.itreetools.org"}>here</a>.
					</Alert>
				</Col>
			</Row>
		</Container>
	)
};
